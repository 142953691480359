import Badge from 'components/dist/atoms/Badge';
import Button from 'components/dist/atoms/Button';
import Icon, { IconProps } from 'components/dist/atoms/Icon';
import Separator from "components/dist/atoms/Separator";
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { format, parseISO } from "date-fns";
import React from "react";
import { LoanRoleDto } from 'src/backend';
import { CopyContainer } from 'src/components/common/copy-container.component';
import { CreditScoreChip } from 'src/components/credit-score-chip';
import { LoanRoleGuard } from "src/components/user/loan-role-guard";
import { UserAvatar } from "src/components/user/user-avatar"
import { JestTestId } from "src/constants/tests";
import { Permission } from "src/constants/user";
import { useConsolidatedTasks } from 'src/hooks/use-consolidated-tasks';
import { useGetLoansForCompanyAndUserQuery } from 'src/services/loanApi';
import { Loan } from "src/types/loan";
import { addressToString } from "src/utils/address-to-string";
import { convertPhone } from 'src/utils/convertPhone';
import { stopPropagation } from "src/utils/dom/stop-propagation";
import { getInitials } from 'src/utils/get-initials';
import { getUserDisplayName } from "src/utils/user/get-user-display-name";
import { isRoleABorrower } from 'src/utils/user/is-role-a-borrower';


interface LoanOverviewPersonTooltipCardProps {
    role: LoanRoleDto;
    loan: Loan;
    labels: string[];
    showRoleChip?: boolean;
    driversLicenseVisible: boolean;
    driversLicenseStatus: string;
    isCreditScoreVisible: boolean;
    canSeeDriversLicense: boolean;
    rolesEnabled: boolean;
    onEdit?: (id: string) => void;
    onDelete?: (roleId: string) => void;
    onSendMessageClick: (userId: string) => void;
}

export const LoanOverviewPersonTooltipCard = (props: LoanOverviewPersonTooltipCardProps) => {
    const roleEntity = props.loan.loanEntities.find(entity => entity.sherpaEntity.userIndividualId === props.role.user.id);
    const { tasksCountPerUserIdMap } = useConsolidatedTasks({ loanId: props.loan.id })
    const { data = [] } = useGetLoansForCompanyAndUserQuery({
        companyId: props.loan.lender.id,
        userId: props.role.user.id,
    }, {
        skip: !props.loan.lender.id || !props.role.user.id
    })

    let messagingPermission = Permission.BorrowerTeamCommunication;
    if (props.role.role.includes('LENDER')) {
        messagingPermission = Permission.LendingTeamCommunication;
    }

    const itemList: {
        title: string
        icon: IconProps['name']
        copyable: boolean
        badge: boolean
        value: string | number
    }[] = [
        {
            title: 'Tasks',
            icon: 'NumberedListLeft' as const,
            copyable: false,
            badge: true,
            value: tasksCountPerUserIdMap[props.role.user.id]
        },
        {
            title: 'Loans',
            icon: 'Handbag' as const,
            copyable: false,
            badge: true,
            value: data.length
        },
        {
            title: 'ID No',
            icon: 'UserSquare' as const,
            copyable: true,
            badge: false,
            // @ts-expect-error
            value: roleEntity?.mostRecentDriversLicenseInformation?.number
        },
        {
            title: 'EXP',
            icon: "ClockRotateRight" as const,
            copyable: false,
            badge: false,
            // @ts-expect-error
            value: roleEntity?.mostRecentDriversLicenseInformation?.expiration ? format(parseISO(roleEntity.mostRecentDriversLicenseInformation?.expiration), 'MM-dd-yyyy') : ''
        },
        {
            title: 'DOB',
            icon: "Calendar" as const,
            copyable: false,
            badge: false,
            // @ts-expect-error
            value: roleEntity?.mostRecentDriversLicenseInformation?.dob ? format(parseISO(roleEntity.mostRecentDriversLicenseInformation?.dob), 'MM-dd-yyyy') : ''
        },
        {
            title: 'State',
            icon: "MapPin" as const,
            copyable: false,
            badge: false,
            // @ts-expect-error
            value: roleEntity?.mostRecentDriversLicenseInformation?.state
        }
    ].filter(item => !!item.value);
    // @ts-expect-error
    const residentialAddresses = roleEntity?.mostRecentUserInformation?.residentialAddresses ?? [];
    let address = residentialAddresses.find(address => address.address.currentAddress)?.address;
    if (!address && residentialAddresses.length > 0) {
        address = residentialAddresses[0].address;
    }

    const addressString = addressToString(address);
    /* @ts-expect-error */
    const isItemListVisible = (!!roleEntity && props.driversLicenseVisible && roleEntity?.mostRecentDriversLicenseInformation?.number) || !isRoleABorrower(props.role.role)

    return (<div className="p-4 flex flex-col gap-4" onClick={stopPropagation}>
        <Stack row items='start' space='xs'>
            <UserAvatar
                role={props.role.role}
                avatarDocId={props.role.user.avatarDocId}
                userId={props.role.user.id}>
                {getInitials(getUserDisplayName(props.role.user))}
            </UserAvatar>
        </Stack>
        <Stack items='center' row space='md'>
            <Text size='sm' truncate weight='medium' className='mt-2'>
                {getUserDisplayName(props.role.user)}
            </Text>
            {props.isCreditScoreVisible && <CreditScoreChip
                loanId={props.loan.id}
                userId={props.role.user.id}
                creditReportError={props.role.creditReportError}
                softCreditStatus={props.role.softCreditStatus}
                creditScore={props.role.creditScore} />}
        </Stack>
        <div className="flex flex-wrap gap-1">
            {props.labels.map(label => <Badge variant="label" className='gap-2 h-6 capitalize font-normal' key={label}>
                {label}
            </Badge>)}
        </div>
        {isItemListVisible && <>
            <Separator />
            <Stack space='md'>
                {itemList.map((item) => (<Stack
                    as={item.copyable ? CopyContainer : 'div'}
                    {...item.copyable ? { text: item.value } : {}}
                    className="overflow-hidden" row space='sm'
                    key={item.title}>
                    <Icon strokeWidth={1.5} className='text-black-primary' name={item.icon} width={20} height={20} />
                    <Text size='sm' truncate>{item.title}:</Text>
                    {item.badge ? <Badge variant='warning' circle size='md'>{item.value}</Badge> : <Text size='sm' truncate>{item.value}</Text>}
                    {(item.title === 'EXP' && props.canSeeDriversLicense && props.driversLicenseStatus === 'EXPIRED') &&
                        <Badge variant='destructive'  >ID Expired</Badge>}
                    {(item.title === 'EXP' && props.canSeeDriversLicense && props.driversLicenseStatus === 'EXPIRING') &&
                        <Badge color='custom' className="text-yellow-70 bg-yellow-5 border-yellow-5" >ID Expiring</Badge>}
                </Stack>))}
            </Stack>
        </>}
        <Separator />
        <Stack space='xs'>
            <CopyContainer text={props.role.user.emailAddress}>
                <Icon strokeWidth={1.5} className='text-black-primary' name="MessageText" width={20} height={20} />
                <Text
                    truncate
                    size='sm'
                    as='a'
                    rel="noreferrer"
                    target='_blank'
                    href={`mailto:${props.role.user.emailAddress}`}>
                    {props.role.user.emailAddress}
                </Text>
            </CopyContainer>
            {addressString && <CopyContainer text={addressString}>
                <Icon strokeWidth={1.5} className='text-blue-100' name='MapPin' width={20} height={20} />
                <Text size='sm' className='flex-1' truncate>
                    {addressString}
                </Text>
            </CopyContainer>}
            {props.role.user.mobilePhone?.value && <CopyContainer text={props.role.user.mobilePhone?.value}>
                <Icon strokeWidth={1.5} className='text-black-primary' name='Phone' width={20} height={20} />
                <Text
                    truncate
                    size='sm'
                    as='a'
                    rel="noreferrer"
                    target='_blank'
                    href={`tel:+1${convertPhone(props.role.user.mobilePhone?.value)}`}>
                    {convertPhone(props.role.user.mobilePhone?.value)}
                </Text>
            </CopyContainer>}
        </Stack>
        <LoanRoleGuard permission={messagingPermission}>
            <Separator />
            <div>
                <Button
                    block
                    data-test={JestTestId.LoanOverviewPersonTooltipCardSendMessageButton}
                    onClick={() => props.onSendMessageClick(props.role.user.id)}>
                    Send Message
                </Button>
            </div>
        </LoanRoleGuard>
    </div>)
}

